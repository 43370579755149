var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters,"hide-search-box":true},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},(_vm.isSuperAdmin)?{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true}:null,{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.deliveryUid",fn:function(ref){
var item = ref.item;
return [(item.deliveryUid)?[_vm._v(_vm._s(item.deliveryUid))]:[_vm._v("-")]]}},{key:"item.periodStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.periodStart.substr(0, 10)) .locale(_vm.locale) .format('L'))+" ")]}},{key:"item.periodEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.periodEnd.substr(0, 10)) .locale(_vm.locale) .format('L'))+" ")]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company)?[_vm._v(_vm._s(item.company.name))]:[_vm._v("-")]]}},{key:"item.restaurant",fn:function(ref){
var item = ref.item;
return [(item.restaurant)?[_vm._v(_vm._s(item.restaurant.name))]:[_vm._v("-")]]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,_vm.currencyCode))+" ")]}},{key:"item.amountDue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amountDue,_vm.currencyCode))+" ")]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [(!_vm.isSuperAdmin)?_c('table-button',{attrs:{"tooltip":_vm.$t('invoices.download'),"color":"blue-grey","icon":"file_download","approve-color":"error"},on:{"execute":function($event){;(_vm.selectedFile = item),
                                (_vm.fileDownloadDialog = true)}}}):_vm._e(),(_vm.isSuperAdmin)?_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}):_vm._e(),(_vm.isSuperAdmin)?_c('delete-action',{attrs:{"entity-name":item.invoiceNumber,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}}):_vm._e()]}}],null,true)}),(_vm.isSuperAdmin)?_c('confirm-modal',{attrs:{"title":_vm.$t('texts.are_you_sure'),"text":_vm.$t('texts.you_will_delete_this_item', {
                        item: ("" + _vm.selectedInvoiceNumber),
                    }),"approve-color":"red","approve-text":_vm.$t('actions.delete')},on:{"onApprove":function($event){return _vm.deleteFile(_vm.selectedFile.id)},"onCancel":function($event){_vm.selectedFile = null}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),(!_vm.isSuperAdmin)?_c('invoice-download-modal',{attrs:{"item":_vm.selectedFile || null},on:{"onApprove":function($event){return _vm.deleteFile(_vm.selectedFile.id)},"onCancel":function($event){_vm.selectedFile = null}},model:{value:(_vm.fileDownloadDialog),callback:function ($$v) {_vm.fileDownloadDialog=$$v},expression:"fileDownloadDialog"}}):_vm._e()]}},(_vm.isSuperAdmin)?{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }